import React, { useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import { parseSeo } from '../helpers/seo'
import { SPACING, COLORS } from '../helpers/constants'
import Layout from '../views/Layout'
import SEO from '../views/Seo'
import { Breadcrumbs } from '../components/Breadcrumbs'
import { Divider } from '../components/Divider'
import { Content } from '../components/Content'
import { Text } from '../components/Text'
import { Container } from '../components/Container'
import { WpBlocksGenerator } from '../helpers/wpBlocksGenerator'
import { PageContent } from '../components/PageContent'
import { Grid } from '../components/Grid'
import { Col } from '../components/Col'
import { ProductCard } from '../components/ProductCard'
import { AppContext, doFetchStockStatus } from '../config/context'
import { useTranslation } from '../hooks/useTranslation'

export default ({ data }) => {
  const { serie } = data.wordpress
  const [state, dispatch] = useContext(AppContext)
  const { products } = state

  const getTranslation = useTranslation()

  const { id: serieId, title, blocks = [], seo } = serie

  const productsInSerieIndex = blocks.findIndex(
    block => block?.__typename === 'WordPress_AcfProductsInSerieBlock'
  )

  const [upperBlocks, lowerBlocks] =
    productsInSerieIndex > -1
      ? [
          blocks.slice(0, productsInSerieIndex),
          blocks.slice(productsInSerieIndex + 1), // Skip the productsInSerie block
        ]
      : [blocks, []]

  /**
   * Fetch stock status
   */
  useEffect(() => {
    dispatch(doFetchStockStatus())
  }, [])

  return (
    <Layout>
      <SEO {...parseSeo(seo)} />
      <Breadcrumbs breadcrumbs={seo.breadcrumbs} />
      <PageContent>
        <WpBlocksGenerator blocks={upperBlocks} post={serie} />

        <Container>
          <Divider
            line={COLORS.ADAPTED_LIGHT_BLUE}
            size={{
              desktop: SPACING.XX_LARGE,
              tablet: SPACING.X_LARGE,
              mobile: SPACING.LARGE,
            }}
          />

          <Grid col={3}>
            <Col>
              <Content>
                <Text>
                  {getTranslation('modelsInText')} {title}
                  {getTranslation('seriesText')}
                </Text>
              </Content>
            </Col>
            <Col column={'2 / -1'}>
              <Grid col={3} mobileCol={2}>
                {products.map((product, index) => {
                  const {
                    acfAttributes: { productSerie },
                  } = product

                  return (
                    productSerie &&
                    productSerie.id === serieId && (
                      <ProductCard key={index} product={product} />
                    )
                  )
                })}
              </Grid>
            </Col>
          </Grid>
        </Container>

        {lowerBlocks.length > 0 && (
          <>
            <Divider
              size={{
                desktop: SPACING.MEDIUM,
                tablet: SPACING.MEDIUM,
                mobile: SPACING.MEDIUM,
              }}
            />
            <WpBlocksGenerator blocks={lowerBlocks} post={serie} />
          </>
        )}
      </PageContent>
    </Layout>
  )
}

export const query = graphql`
  query($id: ID!) {
    wordpress {
      serie(id: $id) {
        ...Single_Serie
      }
    }
  }
`
